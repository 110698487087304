<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal
            id="modalResponse"
            title="PopUp Response"
            cancel-variant="outline-secondary"
            scrollable
          >
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>
          <!-- table -->
          <b-table
            :items="dataItems"
            :fields="items"
            :per-page="perPage"
            :current-page="currentPage"
            hover
            small
            caption-top
            responsive
          >
            <!-- Formato con el componente de hacer el formateo de las  cifras -->
            <template #cell(debit)="data">
              {{ data.item.debit | currency({ symbol: "" }) }}
            </template>
            <template #cell(credit)="data">
              {{ data.item.credit | currency({ symbol: "" }) }}
            </template>
            <template #cell(balance)="data">
              {{ data.item.balance | currency({ symbol: "" }) }}
            </template>
          </b-table>
          <!-- Totalizacion -->
          <b-table-simple
            class="mt-2"
            hover
            small
            caption-top
            responsive
          >
            <b-thead head-variant="light" style="text-align:center;">
              <b-th style="width:25%;"></b-th>
              <b-th>Ventas: {{ debit | currency({ symbol: "" }) }}</b-th>
              <b-th>Ganado:{{ credit | currency({ symbol: "" }) }}</b-th>
              <b-th>Profit: {{ profit | currency({ symbol: "" }) }}</b-th>
            </b-thead>
          </b-table-simple>
          <!-- paginador -->
          <b-pagination
            v-model="currentPage"
            :total-rows="cant"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import axios from "@axios";
// import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDate } from "@core/utils/filter";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      items: [
        { label: "Fecha", key: "createdAt" },
        { label: "Transacción", key: "transactionID" },
        { label: "Juego", key: "game" },
        { label: "Debito", key: "debit" },
        { label: "Credito", key: "credit" },
        { label: "Balance", key: "balance" },
      ],
      showFilter: false,
      loadingData: false,
      dataItems: [],
      credit: 0,
      debit: 0,
      profit: 0,
      balance: 0,
      cant: 0,
      dataIdUser: [],
      pages: [20, 40, 60],
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      customerOptions: [],
      currencyssOptions: [],
      filterSelect: {
        selectProduct: "",
        dateFrom: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
    };
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  filters: {
    formatDate,
  },
  methods: {
    async detail() {
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const productId = new URL(location.href).searchParams.get('productId')
      const userId = new URL(location.href).searchParams.get('userId')
      const dateFrom = new URL(location.href).searchParams.get('dateFrom')
      const dateAt = new URL(location.href).searchParams.get('dateTo')
      const params = {
          productId: productId,
          userId: userId,
          dateFrom: dateFrom,
          dateAt: dateAt
        };
      let result = await axios
        .post(`${VUE_APP_URL}/detailProfit`, params)
        .then((r) => (
          this.dataItems = r.data.DetailData,
          this.credit = r.data.credit,
          this.debit = r.data.debit,
          this.profit = r.data.profit,
          this.balance = r.data.balance,
          this.cant = r.data.cant
          )
        )
        .catch((error) => console.log(error.response));
      return result;
    }
  },
  mounted() {
    this.detail();
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

